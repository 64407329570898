import { combineReducers } from 'redux';
import customizer from "./customizer/"
import navbar from "./navbar/Index"
//import auth from "./auth/"

import main from './main.reducer';
import auth from './auth.reducer';
import reservation from './reservation.reducer';

const rootReducer = combineReducers({
  customizer: customizer,
  navbar: navbar,
  main: main,
  auth: auth,
  reservation: reservation
})

export default rootReducer
