export function getLogo(company) {

    if (company && company.theme.logo && company.theme.logo.startsWith('https')) {
        return company.theme.logo;
    }

    return company.baseUrl + company.theme.logo
}

export function getLogoBuy(company) {

    if (company && company.theme.logoBuy && company.theme.logo.startsWith('https')) {
        return company.theme.logoBuy;
    }

    return company.baseUrl + company.theme.logoBuy
}

export function getCover(company) {

    if (company && company.theme.memberCover && company.theme.memberCover.startsWith('https')) {
        return company.theme.memberCover;
    }

    return company.baseUrl + company.theme.memberCover;
}