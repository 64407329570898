import React from "react"
import {
  Button,
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  Badge,
  NavItem,
  NavLink
} from "reactstrap"
import moment from 'moment'
import { Edit2, Settings, Menu, X, LogOut } from "react-feather"
import coverImg from "../../assets/img/profile/user-uploads/back-nrfight.jpg"
import profileImg from "../../assets/img/profile/user-uploads/user-13.jpg"
import menuConfig from "../../configs/menuConfig"
import { Link, withRouter } from "react-router-dom"
import { history } from "../../history"
import { getCover } from "../../helpers/url.helper"

class ProfileHeader extends React.Component {
  state = {
    isOpen: false
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  redirect = (route) => {
    history.push(route);
  }

  render() {

    const lastPushs = this.props.member ? this.props.member.lastPushs : [];

    if (lastPushs.length > 0) {
      var nIndex = menuConfig.findIndex(p => p.id == 'notification');
      menuConfig[nIndex].badge = 'warning';
      menuConfig[nIndex].badgeText = lastPushs.length;
    }

    const reservations = this.props.member ? this.props.member.reservations : [];
    const nbResa = reservations.filter(r => moment(r.dateStart).isAfter()).length;

    if (nbResa > 0) {
      var nIndex = menuConfig.findIndex(p => p.id == 'reservation');
      menuConfig[nIndex].badge = 'success';
      menuConfig[nIndex].badgeText = nbResa;
    }

    var cover = getCover(this.props.company);

    return (
      <div className="profile-header mb-2">
        <div className="position-relative">
          <div className="cover-container" style={{ background: `url(${cover}) rgb(0 0 0 / 30%)`, height: this.props.company.theme.memberCover ? 150 : 40 }}>

            <Button color="primary" size="sm" className="btn-icon mb-1 mr-1 rounded-circle " style={{ position: 'absolute', right: 0, top: 10 }} onClick={() => this.props.logout()}>
              <LogOut />
            </Button>
          </div>
          <div className="profile-img-container d-flex align-items-start justify-content-start">
            <img
              src={this.props.memberImage}
              alt="porfileImg"
              className="img-fluid img-border rounded-circle box-shadow-1"
            />

          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center profile-header-nav" style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>


          <Navbar expand="sm" className="w-100 pr-0">

            {this.props.company.mobileAppConfig["scanAccess"] && false ? (
              <Button outline color="primary" size="sm" className="btn-icon mr-1 scan-quick-access" onClick={() => this.redirect('/member/scan')}>
                Scanner le QRCode
              </Button>
            ) : null}
            <NavbarToggler onClick={this.toggle}>

              {this.state.isOpen ? <X size={20} /> : <Menu />}
            </NavbarToggler>
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="justify-content-around w-75 ml-sm-auto navbar-nav">

                {menuConfig.map(({ permission, title, icon, navLink, badge, badgeText, id }, index) => {

                  if (id == 'offers') {
                    return this.props.company.mobileAppConfig.buyLink ? (
                      <li class="px-sm-0 nav-item dzdzza">
                        <a href={this.props.company.baseUrl + '/offers-buy/' + this.props.member.email} target="_blank">
                          {icon} {title}
                        </a>
                      </li>
                    ) : null
                  }

                  //if (permission == 'scanAccess') return;
                  if (this.props.company.mobileAppConfig[permission] || this.props.company.mobileAppConfig[permission] === undefined) {
                    return (
                      <NavItem className="px-sm-0" key={index}>
                        <Link to={navLink} className={this.props.location.pathname == navLink ? "current-active" : ''}>
                          {icon} {title}
                          {badge && badgeText ? <Badge color={badge} className="badge-main-menu" pill>{badgeText}</Badge> : null}
                        </Link>
                      </NavItem>
                    )
                  }
                })}


              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </div>
    )
  }
}
//export default ProfileHeader
export default withRouter(ProfileHeader);
