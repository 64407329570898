import { LOGIN_SUCCESS, LOGIN_FAILURE, RESET_PASSWORD } from '../constants/auth.constants';
import Swal from 'sweetalert2'

export default (state = {}, action) => {
    switch (action.type) {
        case LOGIN_FAILURE:
            Swal("Erreur", action.message, "error");
            return { ...state, inProgress: false };

        case LOGIN_SUCCESS:
            Swal("Connexion réussi", "Bonjour " + action.member.name, "success");
            return {
                ...state,
                inProgress: false,
                errors: action.error ? action.error : null
            };
        case RESET_PASSWORD:
            Swal("Un email vous a été envoyé", "Merci de suivre la procèdure de changement de mot de passe", "success");
            return state;
        default:
            return state;
    }
};
