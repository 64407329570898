import React, { Suspense, lazy } from "react"
import * as Icon from "react-feather"
import * as ReactIcon from "react-icons/io5";

const Planning = lazy(() => import("./../views/pages/member/planning"))
const Reservation = lazy(() => import("./../views/pages/member/Reservation"))
const Club = lazy(() => import("./../views/pages/member/club"))
const Setting = lazy(() => import("./../views/pages/member/Setting"))
const Notification = lazy(() => import("./../views/pages/member/Notification"))
//const ScanAccess = lazy(() => import("./../views/pages/member/ScanAccess"))
const News = lazy(() => import("./../views/pages/member/News"))

const menuConfig = [
  /*
  {
    id: "club",
    title: "Le club",
    type: "item",
    icon: <Icon.Home size={20} />,
    permission: "home",
    navLink: "/home",
    component: Club
  },*/
  {
    id: "planning",
    title: "Planning",
    type: "item",
    icon: <ReactIcon.IoApps size={20} />,
    permission: "planning",
    navLink: "/member/planning",
    component: Planning
  },
  {
    id: "reservation",
    title: "Réservations",
    type: "item",
    icon: <ReactIcon.IoCalendar size={20} />,
    permission: "reservation",
    navLink: "/member/reservation",
    component: Reservation
  },
  /*
  {
    id: "scan",
    title: "Scan",
    type: "item",
    master: true,
    icon: <ReactIcon.IoQrCode size={20} />,
    permission: "scanAccess",
    navLink: "/member/scan",
    master: true,
    component: ScanAccess
  },*/
  {
    id: "actu",
    title: "Actu.",
    type: "item",
    master: false,
    icon: <ReactIcon.IoNewspaperOutline size={20} />,
    //permission: "scanAccess",
    navLink: "/member/actualite",
    component: News
  },
  {
    id: "offers",
    title: "Achats",
    type: "a",
    icon: <ReactIcon.IoCart size={20} />,
    permission: "buyLink",
    navLink: "/unique_for_active",
    component: Notification
  },
  {
    id: "notification",
    title: "Notif.",
    type: "item",
    icon: <ReactIcon.IoNotifications size={20} />,
    permission: "notification",
    navLink: "/member/notification",
    component: Notification
  },
  {
    id: "profile",
    title: "Profil",
    type: "item",
    icon: <ReactIcon.IoPerson size={20} />,
    navLink: "/member/setting",
    component: Setting
  }
]

export default menuConfig
