import agent from './../../services/sportigo';
import { history } from "./../../history"
import { ASYNC_START, ASYNC_END } from './../constants/main.constants';
import { LOGIN_SUCCESS, LOGOUT } from './../constants/auth.constants';

const promiseMiddleware = store => next => action => {
  if (isPromise(action.payload)) {
    store.dispatch({ type: ASYNC_START, subtype: action.type });

    action.payload.then(
      res => {
        store.dispatch({ type: ASYNC_END, promise: action.payload });
      },
      error => {
        action.error = true;
        store.dispatch({ type: ASYNC_END, promise: action.payload });
      }
    );

    return;
  }

  next(action);
};

const localStorageMiddleware = store => next => action => {
  //if (action.type === REGISTER || action.type === LOGIN) {
  if (action.type === LOGIN_SUCCESS) {
    window.localStorage.setItem('sportigo-user', action.member.appToken);
    agent.setToken(action.member.appToken);
  }

  if (action.type === LOGOUT || action.type === "AUTH_LOGOUT_NO_REDIRECT") {
    window.localStorage.setItem('sportigo-user', '');
    agent.setToken(null);
  }

  next(action);
};

function isPromise(v) {
  return v && typeof v.then === 'function';
}

export { promiseMiddleware, localStorageMiddleware }
