import { ADD_SUCCESS, REMOVE_FAILURE, REMOVE_SUCCESS } from '../constants/reservation.constants';
import Swal from 'sweetalert2'

const defaultState = {
    showBookModal: false,
    refreshCalendar: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'RESERVATION_REFRESH_FINISH':
            return { ...state, refreshCalendar: false };
        case ADD_SUCCESS:
            var title = action.waiting ? "Vous êtes sur la liste d'attente" : "Réservation validé";
            var body = action.waiting ? "Vous serez automatiquement inscrit si une place se libere." : "Votre réservation est validé. Vous pouvez suivre ou annuler vos réservations dans l'onglet réservation.";
            Swal(title, body, "success");
            return { ...state, inProgress: false, refresh: true, refreshEvents: true, showBookModal: false, refreshCalendar: true };

        case REMOVE_SUCCESS:
            Swal("Réservation annulée.", "Votre réservation a été annulée.", "success");
            return { ...state, inProgress: false, refresh: true, refreshEvents: true };

        case REMOVE_FAILURE:
            Swal("Annulation échouée", action.message, "error");
            return { ...state, inProgress: false, refresh: true, refreshEvents: true };

        case 'RESERVATION_OPEN_MODAL':
            return { ...state, showBookModal: true };

        case 'RESERVATION_CLOSE_MODAL':
            return { ...state, showBookModal: false };
        default:
            return state;
    }
};
