import React from "react"
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import axios from "axios"
import { Link } from 'react-router-dom';
import * as Icon from "react-feather"
import classnames from "classnames"
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent"
import { history } from "../../../history"
import { store } from "../../../redux/storeConfig/store"


const UserDropdown = props => {

  const handleLogout = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('{ "type": "logout" }');
    } else {
      postMessage('{ "type": "logout" }');
    }
    store.dispatch({ type: 'AUTH_LOGOUT' })
    /*history.push("/logout")*/
  }

  return (
    <DropdownMenu right>
      {/*
      <DropdownItem tag="a" href="#">
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.Mail size={14} className="mr-50" />
        <span className="align-middle">My Inbox</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.CheckSquare size={14} className="mr-50" />
        <span className="align-middle">Tasks</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.MessageSquare size={14} className="mr-50" />
        <span className="align-middle">Chats</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.Heart size={14} className="mr-50" />
        <span className="align-middle">WishList</span>
      </DropdownItem>
      <DropdownItem divider />
      */}
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleLogout()}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Déconnexion</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: []
  }

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult })
    })
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }

  truncateString(str, num) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">

        {/*
        <NavItem className="nav-search">
          <NavLink className="nav-link-search">
            <Icon.Calendar size={21} />
          </NavLink>
        </NavItem>
        */}

        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.Bell size={21} />
            {this.props.member.lastPushs.length > 0 ? (
              <Badge pill color="primary" className="badge-up">
                {" "}
                {this.props.member.lastPushs.length}{" "}
              </Badge>
            ) : null}

          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <h3 className="text-white">{this.props.member.lastPushs.length} message{this.props.member.lastPushs.length > 1 ? 's' : ''}</h3>
                <span className="notification-title">Message de votre club</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false
              }}
            >

              {this.props.member.lastPushs ? this.props.member.lastPushs.map(({ date, message, subject }, index) => {
                return <div className="d-flex justify-content-between" key={`key_header_${index}`}>
                  <Media className="d-flex align-items-start">
                    {/*<Media left href="#">
                      <Icon.PlusSquare
                        className="font-medium-5 primary"
                        size={21}
                      />
                    </Media>
                    */}
                    <Media body>
                      <Media heading className="primary media-heading" tag="h6">
                        {subject}
                      </Media>
                      <p className="notification-text">
                        {this.truncateString(message, 50)}
                      </p>
                    </Media>
                    <small>
                      <time
                        className="media-meta"
                        dateTime="2015-06-11T18:29:20+08:00"
                      >
                        {date}
                      </time>
                    </small>
                  </Media>
                </div>;
              }) : null}

            </PerfectScrollbar>
            <li className="dropdown-menu-footer">
              <DropdownItem tag="span" className="p-1 text-center">
                <Link to="/member/notification" className="align-middle">Voir toutes les notifications</Link>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
              <span className="user-status">{this.props.userMail}</span>
            </div>
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}
export default NavbarUser
