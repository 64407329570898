export const APP_LOAD_COMPANY_SUCCESS = 'APP_LOAD_COMPANY_SUCCESS';
export const APP_LOAD_COMPANY_FAILED = 'APP_LOAD_COMPANY_FAILED';

export const APP_LOAD_USER_FAILED = 'APP_LOAD_USER_FAILED';
export const APP_LOAD_USER_SUCCESS = 'APP_LOAD_USER_SUCCESS';

export const APP_REFRESH_USER = 'APP_REFRESH_USER';
export const APP_REFRESH_COACHS = 'APP_REFRESH_COACHS';


export const REDIRECT = 'REDIRECT';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';