import { APP_LOAD_COMPANY_SUCCESS, APP_LOAD_USER_FAILED, APP_LOAD_USER_SUCCESS, REDIRECT, ASYNC_START, ASYNC_END, APP_REFRESH_USER, APP_REFRESH_COACHS } from '../constants/main.constants';
import { LOGOUT, LOGIN_SUCCESS, RESET_PASSWORD } from '../constants/auth.constants';
import { ADD_SUCCESS, ADD_FAILURE } from '../constants/reservation.constants';
import { history } from './../../history';
import Swal from 'sweetalert2'

const defaultState = {
  company: null,
  config: null,
  club: null,
  member: null,
  appName: 'Sportigo',
  appCompanyLoaded: false,
  appUserLoaded: false,
  inProgress: false,
  token: null,
  viewChangeCounter: 0,
  redirectTo: null
};

export default (state = defaultState, action) => {

  var coachs = [];

  switch (action.type) {

    case APP_LOAD_COMPANY_SUCCESS:
      var club = action.company.clubs[0];

      return {
        ...state,
        company: action.company,
        club: club,
        config: action.company.mobileAppConfig,
        appCompanyLoaded: true,
      };

    case LOGIN_SUCCESS:
      return { ...state, member: action.member, appUserLoaded: true, redirectTo: "/member" };
    case APP_LOAD_USER_SUCCESS:
      return { ...state, member: action.member, appUserLoaded: true };
    case "APP_NO_USER":
    case APP_LOAD_USER_FAILED:
    case LOGOUT:
      //window.postMessage('{ "type": "logout" }', "*");
      var message = '{ "type": "logout" }';
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message);
      } else {
        postMessage(message);
      }
      history.push('/');
      return { ...state, appUserLoaded: true, redirectTo: '/', member: null };

    case "AUTH_LOGOUT_NO_REDIRECT":
      //window.postMessage('{ "type": "logout" }', "*");
      var message = '{ "type": "logout" }';
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message);
      } else {
        postMessage(message);
      }
      return { ...state, appUserLoaded: true, member: null };

    //case ADD_SUCCESS:
    //  return { ...state, redirectTo: '/member/reservation' };
    case APP_REFRESH_USER:
      return { ...state, member: action.member };
    case ADD_SUCCESS:
      return { ...state, member: action.member, redirectTo: '/member/reservation' };

    case ADD_FAILURE:
      var callback = () => { };

      //console.log('action.company.mobileAppConfig.buyLink : ', state.company.mobileAppConfig.buyLink);

      if (state.company.baseUrl && action.message && action.message.includes('crédit') && state.company.mobileAppConfig.buyLink) {
        var baseUrl = state.company.baseUrl && state.company.baseUrl.includes('8888') ? state.company.baseUrl + '/app_dev.php' : state.company.baseUrl;
        var offerUrl = baseUrl + '/offers-buy/' + state.member.email;
        //console.log('open ' + offerUrl);
        //callback = () => window.open(offerUrl, "_blank");
        callback = () => {
          var message = '{ "type": "link", "url": "' + offerUrl + '" }';
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(message);
          } else {
            window.open(offerUrl, "_blank")
          }
          //window.ReactNativeWebView.postMessage();
        }
      }

      Swal("Erreur", action.message, "error").then(callback);
      return { ...state, inProgress: false, refreshEvents: true };

    case "OPEN_OFFERS":
      var baseUrl = state.company.baseUrl.includes('8888') ? state.company.baseUrl + '/app_dev.php' : state.company.baseUrl;
      var offerUrl = baseUrl + '/offers-buy/' + state.member.email;

      var message = '{ "type": "link", "url": "' + offerUrl + '" }';
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message);
      } else {
        window.open(offerUrl, "_blank")
      }

      return { ...state, inProgress: false, refreshEvents: true };

    case ASYNC_START:
      return { ...state, inProgress: true };
    case ASYNC_END:
      return { ...state, inProgress: false };

    case "PUBLIC_PAGE":
      return { ...state, appUserLoaded: true };

    case RESET_PASSWORD:
      return { ...state, inProgress: false, member: null, redirectTo: '/' };

    case REDIRECT:
      return { ...state, redirectTo: null };
    default:
      return state;
  }
};
