import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = '/api/sportigo';

//const encode = encodeURIComponent;
const responseBody = res => res.body;

let token = "dd";
let host = window.location.href.split('.').shift().replace('https://', '').replace('http://', '');

const tokenPlugin = req => {
  if (token) {
    //req.set('sportigo-token', `Token ${token}`);
    req.set('sportigo-token', token);
  }
}
const hostPlugin = req => {
  if (host) {
    req.set('sportigo-host', host);
  }
}

const requests = {
  del: url =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin, hostPlugin).then(responseBody),
  get: url =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin, hostPlugin).then(responseBody),
  put: (url, body) =>
    superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin, hostPlugin).then(responseBody),
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin, hostPlugin).then(responseBody)
};

const Company = {
  get: (companyId) =>
    requests.get('/company')
};

const OneSignale = {
  store: (token, userId) =>
    requests.post('/onesignale/store', { token: token, userId: userId }),
  last: () =>
    requests.get('/onesignale/push'),
};

const Reservation = {
  giftcard: (data) =>
    requests.post('/transaction/giftcard', data),
  appointment: (eventId, disciplineId, eventDate, firstname, lastname, email, phone) =>
    requests.post('/reservation/appointment', { eventId: eventId, disciplineId: disciplineId, date: eventDate, firstname: firstname, lastname: lastname, email: email, phone: phone }),
  appointmentLogin: (eventId, disciplineId, eventDate, email, password) =>
    requests.post('/reservation/appointment-login', { eventId: eventId, disciplineId: disciplineId, date: eventDate, email: email, password: password }),
  appointmentMember: (eventId, eventDate) =>
    requests.post('/reservation/appointment-member', { eventId: eventId, date: eventDate }),
  add: (eventId, eventDate, members, coaching, discipline, nbFriends) =>
    requests.post('/reservation', { eventId: eventId, date: eventDate, members: members, coaching: coaching, discipline: discipline, nbFriends: nbFriends }),
  del: (resaId) =>
    requests.del(`/reservation/${resaId}`)
};

const Coaching = {
  add: (tokenId, coachId, eventId, infoReseration) =>
    requests.post('/coaching/book', { event: eventId, token: tokenId, coaching: coachId, infoReseration: infoReseration }),
  addWithCredit: (coachId, eventId, infoReseration) =>
    requests.post('/coaching/book-credit', { event: eventId, coaching: coachId, infoReseration: infoReseration }),

  del: (bookingId) =>
    //requests.del(`/coaching/book/${bookingId}`)
    requests.post('/coaching/remove', { booking: bookingId })
};

const Auth = {
  current: () =>
    requests.get('/member'),

  login: (email, password) =>
    requests.post('/member/auth', { email: email.toLowerCase(), password: password }),

  loginGeneric: (email, password) =>
    requests.post('/member/auth/generic', { email: email.toLowerCase(), password: password }),

  resetPassword: (email) =>
    requests.post('/member/password-reset', { email: email.toLowerCase() }),

  register: (username, email, password) =>
    requests.post('/users', { user: { username, email, password } }),

  save: user =>
    requests.put('/user', { user })
};

const Actu = {
  load: () =>
    requests.get('/news/list')
};

export default {
  Company,
  Auth,
  Coaching,
  Reservation,
  OneSignale,
  Actu,
  setToken: _token => { token = _token; },
};
