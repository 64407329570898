import React from "react"
import { connect } from "react-redux"
import { getLogo } from "../../../helpers/url.helper"
import "../../../assets/scss/components/app-loader.scss"
class SpinnerComponent extends React.Component {
  render() {
    return (
      <div className="fallback-spinner vh-100">
        {this.props.company ?
          <img className="fallback-logo" src={getLogo(this.props.company)} alt="logo" style={{ width: '200px' }} /> :
          null/*<img className="fallback-logo" src={logo} alt="logo" />*/
        }

        <div className="loading">
          <div className="effect-1 effects"></div>
          <div className="effect-2 effects"></div>
          <div className="effect-3 effects"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    company: state.main.company,
  }
}

export default connect(mapStateToProps)(SpinnerComponent)

//export default SpinnerComponent